
import { Component, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import PlayStoreBadge from "~/components/molecules/display/badges/PlayStoreButton.vue";
import AppStoreBadge from "~/components/molecules/display/badges/AppStoreButton.vue";
import { ButtonText, EsyOilContact, PaymentTypes } from "~/operations/messages";

@Component({
  components: {
    RouterLink,
    PlayStoreBadge,
    AppStoreBadge,
  },
})
export default class DownloadApp extends Vue {
  EsyOilContact = EsyOilContact;
  ButtonText = ButtonText;
  PaymentTypes = PaymentTypes;

  beneftitsItems = [
    `Heizöl Preisvergleich von bis zu 820 Händlern`,
    `Preiswecker mit Wunschpreis-oder Fallpreisfunktion`,
    `Kostenlose und schnelle Bestellung per App (Auch per ${PaymentTypes.guaranteedPayment})`,
    `Beratung zur Kaufentscheidung`,
  ];
}
